
  import type { VastgoedexpertsLog } from "@/store/modules/vastgoedexperts.store";
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { formatDate } from "@/utils/formatters";
  import { Action, Getter } from "vuex-class";
  import { bus } from "@/main";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class DossierPartVgeLogs extends Vue {
    @Action("vastgoedexperts/indexLogs") indexLogs!: (payload: { activity_id: number }) => Promise<VastgoedexpertsLog[]>;

    @Prop({ required: true }) activityId!: number;

    loading = false;

    logs: VastgoedexpertsLog[] = [];

    formatDate = formatDate;

    mounted() {
      this.fetchLogs();
    }

    async fetchLogs() {
      this.loading = true;

      this.logs = await this.indexLogs({ activity_id: this.activityId });

      this.loading = false;
    }
  }
