import { render, staticRenderFns } from "./logs.vue?vue&type=template&id=08f74be8&scoped=true"
import script from "./logs.vue?vue&type=script&lang=ts"
export * from "./logs.vue?vue&type=script&lang=ts"
import style0 from "./logs.vue?vue&type=style&index=0&id=08f74be8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f74be8",
  null
  
)

export default component.exports